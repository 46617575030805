.checkout {
    padding: 20px;
    background-color: #fff;
    height: max-content;
}

.checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}

.checkout__lower {
    display: flex;
    width: 100%;
}

.checkout__lower--left {
    width: 100%;
}