.banner {
    width: 100%;
    height: 30px;
    border-bottom: 2px solid #e5e5e5;
}

.header__sticky-container {
    position: sticky;
    background-color: #fff;
    z-index: 10;
    top: 0;
}

.header {
    display: flex;
    align-items: center;
    height: 66px;
    max-width: 1100px;
    margin: auto;
}

.header__logo {
    width: 100px;
    margin-left: 20px;
    margin-right: 20px;
}

.header__optionBasket {
    color: #222;
    display: flex;
    align-items: center;
}

.header__basketCount {
    margin: 0 10px;
}

.header__nav {
    display: flex;
    justify-content: space-evenly;
}

.header__search {
    display: flex;
    flex: 1;
}

.header__option {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    color: #222;
}

.header__optionBasket > svg {
    width: 28px;
    height: 28px;
}

.header__optionBasket > span {
    border-radius: 13px;
    font-size: 14px;
    padding: 2px;
    text-align: center;
    vertical-align: center;
    width: 20px;
    height: 20px;
    font-family: Arial, sans-serif;
    background-color: #C9002C;
    color: #fff;
    text-decoration: none;
    margin-top: -15px;
    margin-left: -10px;
}

.header__optionLineOne {
    font-size: 12px;
}

.header__optionLineTwo {
    font-size: 15px;
    font-weight: 800;
}

.header__option:hover {
    color: #555;
}

.header__link {
    text-decoration: none;
}

.header__searchInput {
    height: 40px;
    padding: 7px 17px;
    border: solid 1px #a2a2a2;
    width: 100%;
    color: #a2a2a2;
    font-size: 16px;
    border-radius: 8px 0 0 8px;
}

.header__searchInput:focus {
    border: solid 1px darkgrey;
}

.header__searchIcon {
    background-color: #006EFC;
    padding: 7px;
    height: 40px !important;
    width: 40px !important;
    border-radius: 0 8px 8px 0;
    color: #fff;
}