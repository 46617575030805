.checkoutProduct {
    display: flex;
    margin: 20px 0;
}

.checkoutProduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
}

.checkoutProduct__title {
    font-size: 17px;
    font-weight: 800;
}

.checkoutProduct__info {
    padding-left: 20px;
}

.checkoutProduct__rating {
    display: flex;
}

.checkoutProduct__info > button {
    width: 200px;
}