.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    max-height: 500px;
    min-width: 100px;
    width: 300px;
    margin: 10px;
    padding: 20px;
}

.product__info {
    height: 100px;
    margin-top: 15px;
}

.product__price {
    margin-top: 5px;
}

.product__rating {
    display: flex;
    color: #f18E01;
}

.product > img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
}
