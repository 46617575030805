.login {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__logo {
    margin: 20px auto;
    object-fit: contain;
    width: 100px;
}

.login__container {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
}

.login__container > h1 {
    font-weight: 500;
    margin-bottom: 20px;
}

.login__container > form > h5 {
    margin-bottom: 5px;
}

.login__container > form > input {
    height: 30px;
    margin-bottom: 10px;
    background-color: #fff;
    width: 90%;
}

.login__container > p {
    margin-top: 15px;
    font-size: 12px;
}

.login__signinButton {

    background-color: #f0c14b;
    border: 1px solid;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    margin-top: 10px;
    border-radius: 2px;
    height: 30px;
    width: 100%;
}

.login__registerButton {
    background-color: #dddddd;
    border: 1px solid darkgray;
    color: #111;
    margin-top: 10px;
    border-radius: 2px;
    height: 30px;
    width: 100%;
}